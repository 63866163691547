import {useEffect} from 'react';

import {useClient} from '@liveblocks/react';

import {useSubdomain} from './useSubdomain';
import useUser from './useUser';
import {RoomEvent} from '@tetra-next/liveblocks-types';

type EventCallback = ({event}: {event: RoomEvent}) => void | Promise<void>;

const useOrganizationEventListener = (callback: EventCallback) => {
  const client = useClient();

  const subdomain = useSubdomain();
  const {user} = useUser();

  const roomId = user.org_id ? `${subdomain}:${user.org_id}` : null;

  useEffect(() => {
    if (!roomId) return;

    const listener = (eventData) => {
      callback?.(eventData);
    };
    const room = client.getRoom(roomId);
    return room?.events.customEvent.subscribe(listener);
  }, [roomId, callback, client]);
};

export default useOrganizationEventListener;
