import {
  Database,
  Files,
  Folder,
  FolderDashed,
  Gear,
  Globe,
  Layout,
  ListChecks,
  MagicWand,
  MagnifyingGlass,
  Paperclip,
  Pen,
  Tag,
  UserCircleGear,
  UsersThree,
} from '@phosphor-icons/react';

interface Output {
  title?: string | null;
  icon?: JSX.Element;
  entityType?: 'asset' | 'insight' | 'tag' | 'plan-block';
  entityUid: string;
  inProjectUid: string | null;
  inProjectTitle?: string | null;
}

function parsePlanBlock(segments: string[]): Partial<Output> {
  const [planBlockUid] = segments;
  return {
    entityType: 'plan-block',
    entityUid: planBlockUid,
    icon: <Files />,
  };
}

function parseAsset(segments: string[]): Partial<Output> {
  const [assetUid] = segments;
  return {
    entityType: 'asset',
    entityUid: assetUid,
    icon: <Database />,
  };
}

function parseInsight(segments: string[]): Partial<Output> {
  const [insightUid] = segments;
  return {
    entityType: 'insight',
    entityUid: insightUid,
    icon: <MagicWand />,
  };
}

function parseProject(
  segments: string[],
  searchParams: URLSearchParams
): Partial<Output> | null {
  const [segment, ...restSegments] = segments;
  const projectUid = searchParams.get('projectUid');

  switch (segment) {
    case 'asset-fields': {
      return {
        title: 'Fields',
        icon: <ListChecks />,
        inProjectUid: projectUid,
      };
    }
    case 'assets': {
      return {
        title: 'Interviews',
        icon: <Database />,
        inProjectUid: projectUid,
      };
    }
    case 'attachments': {
      return {
        title: 'Supporting Files',
        icon: <Paperclip />,
        inProjectUid: projectUid,
      };
    }
    case 'audience': {
      return {
        title: 'Audience',
        icon: <UsersThree />,
        inProjectUid: projectUid,
      };
    }
    case 'insights': {
      return {
        title: 'Insights',
        icon: <MagicWand />,
        inProjectUid: projectUid,
      };
    }
    case 'materials': {
      return {
        title: 'Materials',
        icon: <Files />,
        inProjectUid: projectUid,
      };
    }
    case 'snippets': {
      return {
        title: 'Snippets',
        icon: <Pen />,
        inProjectUid: projectUid,
      };
    }
    case 'tags': {
      return {
        title: 'Tags',
        icon: <Tag />,
        inProjectUid: projectUid,
      };
    }
    case 'tag': {
      return {
        entityType: 'tag',
        entityUid: searchParams.get('tagUid')!,
        icon: <Tag />,
        inProjectUid: projectUid,
      };
    }
    case 'settings': {
      return {
        title: 'Project Settings',
        icon: <Gear />,
        inProjectUid: projectUid,
      };
    }
    case 'plan-block': {
      return {
        ...parsePlanBlock(restSegments),
        inProjectUid: projectUid,
      };
    }
    case 'insight': {
      return {
        ...parseInsight(restSegments),
        inProjectUid: projectUid,
      };
    }
    case 'asset': {
      return {
        ...parseAsset(restSegments),
        inProjectUid: projectUid,
      };
    }
    case undefined: {
      return {
        title: 'Project Status',
        icon: <Globe />,
        inProjectUid: projectUid,
      };
    }
    default: {
      return null;
    }
  }
}

function parsePersonalSettings(segments: string[]): Partial<Output> | null {
  const [segment] = segments;
  switch (segment) {
    case 'notification': {
      return {
        title: 'Notifications',
        icon: <UserCircleGear />,
      };
    }
    case 'summary-email': {
      return {
        title: 'Email Summary',
        icon: <UserCircleGear />,
      };
    }
    case undefined: {
      return {
        title: 'User Settings',
        icon: <UserCircleGear />,
      };
    }
    default: {
      return null;
    }
  }
}

function parseOrganizationSettings(segments: string[]): Partial<Output> | null {
  const [segment] = segments;
  switch (segment) {
    case 'activity': {
      return {
        title: 'Activity',
        icon: <Gear />,
      };
    }
    case 'audit-log': {
      return {
        title: 'Audit Log',
        icon: <Gear />,
      };
    }
    case 'automations': {
      return {
        title: 'Automations',
        icon: <Gear />,
      };
    }
    case 'project-auto-deletion': {
      return {
        title: 'Project Auto Deletion',
        icon: <Gear />,
      };
    }
    case 'project-fields': {
      return {
        title: 'Project Fields',
        icon: <Gear />,
      };
    }
    case 'roles': {
      return {
        title: 'Roles',
        icon: <Gear />,
      };
    }
    case 'teams': {
      return {
        title: 'Teams',
        icon: <Gear />,
      };
    }
    case 'tetra-bot': {
      return {
        title: 'Tetra Bot',
        icon: <Gear />,
      };
    }
    case 'transcription': {
      return {
        title: 'Transcription',
        icon: <Gear />,
      };
    }
    case 'users': {
      return {
        title: 'Users',
        icon: <Gear />,
      };
    }
    case undefined: {
      return {
        title: 'General Organization Settings',
        icon: <Gear />,
      };
    }
    default: {
      return null;
    }
  }
}

export default function parseRoot(
  segments: string[],
  searchParams: URLSearchParams
): Partial<Output> | null {
  const [segment, ...restSegments] = segments;
  switch (segment) {
    case 'insights-hub': {
      return {
        title: 'Insights Hub',
        icon: <MagicWand />,
      };
    }
    case 'projects': {
      return {
        title: 'Projects',
        icon: <Folder />,
      };
    }
    case 'search': {
      return {
        title: 'Universal Search',
        icon: <MagnifyingGlass />,
      };
    }
    case 'templates': {
      return {
        title: 'Templates',
        icon: <FolderDashed />,
      };
    }
    case undefined: {
      return {
        title: 'Home Dashboard',
        icon: <Layout />,
      };
    }
    case 'my': {
      return parsePersonalSettings(restSegments);
    }
    case 'settings': {
      return parseOrganizationSettings(restSegments);
    }
    case 'project': {
      return parseProject(restSegments, searchParams);
    }
    default: {
      return null;
    }
  }
}
