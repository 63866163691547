import {forwardRef} from 'react';

import MuiAvatar, {AvatarProps as MuiAvatarProps} from '@mui/material/Avatar';
import Image from 'next/image';

interface AvatarProps extends Omit<MuiAvatarProps, 'src'> {
  src?: string | null;
  width: number;
  height: number;
}

const Avatar = forwardRef<HTMLDivElement, AvatarProps>(
  ({alt, height, src, sx, width, ...props}, ref) => {
    if (!src) {
      return (
        <MuiAvatar {...props} sx={{width: width, height: height, ...sx}} ref={ref} />
      );
    }

    return (
      <MuiAvatar {...props} sx={{width: width, height: height, ...sx}} ref={ref}>
        <Image alt={alt ?? ''} height={height} src={src} width={width} />
      </MuiAvatar>
    );
  }
);

Avatar.displayName = 'Avatar';

export default Avatar;
