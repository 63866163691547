import {AlertColor} from '@mui/material/Alert';
import toast, {Toast} from 'react-hot-toast';

import Alert from '@src/components/legacy/Alert';
import {SxProps} from '@mui/material';

interface Props {
  notification?: Toast;
  title?: string;
  message?: string;
  severity?: AlertColor;
  sx?: SxProps;
}

const ToastAlert = ({message, notification, severity, sx, title}: Props) => {
  return (
    <Alert
      alertTitle={title}
      classnames={
        !notification || notification.visible
          ? 'toastNotificationOpen'
          : 'toastNotificationClosed'
      }
      severity={severity}
      sx={sx}
      onClose={
        notification
          ? () => {
              toast.dismiss(notification.id);
            }
          : undefined
      }
    >
      {message}
    </Alert>
  );
};

export default ToastAlert;
