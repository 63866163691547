import {RoomProvider} from '@liveblocks/react';
import Box from '@mui/material/Box';
import Placeholder from '@tiptap/extension-placeholder';
import {EditorContent, useEditor} from '@tiptap/react';

import EditorToolbar from '@src/components/editor/EditorToolbar';
import {useSubdomain} from '@src/hooks/useSubdomain';
import useUser from '@src/hooks/useUser';
import Base from '@src/library/editor-extensions/base';

import {generateEntityRoomId} from '@tetra-next/liveblocks-rooms';
import {useLiveblocksExtension} from '@liveblocks/react-tiptap';

export default function PersonalNotes() {
  const {user} = useUser();
  const organizationId = user.org_id;
  const subdomain = useSubdomain();

  const roomId = generateEntityRoomId({
    subdomain,
    organizationId,
    entityType: 'personalNotes',
    entityUid: user.sub,
  });

  if (!roomId) {
    return null;
  }

  return (
    <RoomProvider id={roomId} initialPresence={{}}>
      <Editor />
    </RoomProvider>
  );
}

function Editor() {
  const liveblocks = useLiveblocksExtension({
    field: 'default',
    offlineSupport_experimental: true,
  });

  const editor = useEditor({
    immediatelyRender: false,
    shouldRerenderOnTransaction: false,
    extensions: [
      ...Base,
      Placeholder.configure({
        placeholder: 'Make your personal notes and checklist here…',
      }),
      liveblocks,
    ],
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        minHeight: 0,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: '1',
          minWidth: 0,
        }}
      >
        <Box sx={{borderBottom: '1px solid #e6ebf3'}}>
          <EditorToolbar editor={editor} users={[]} />
        </Box>

        <EditorContent editor={editor} />
      </Box>
    </Box>
  );
}
