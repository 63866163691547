import {useContext} from 'react';
import SessionContext from '@src/contexts/session';

export default function useUser() {
  const {organization, user} = useContext(SessionContext);

  return {
    user: {
      sub: user?.id ?? '',
      email: user?.email ?? '',
      name: [user?.firstName, user?.lastName].filter(Boolean).join(' '),
      picture: user?.profilePictureUrl,
      org_id: organization?.id,
      org_name: organization?.name,
    },
  };
}
