import {ReactNode} from 'react';

import {Active, UniqueIdentifier} from '@dnd-kit/core';
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {IconButton, Tooltip} from '@mui/material';
import Box from '@mui/material/Box';
import {DotsSixVertical, SidebarSimple, X} from '@phosphor-icons/react';
import {PanelResizeHandle, PanelResizeHandleProps} from 'react-resizable-panels';

import {DashboardCardId} from './cards/DashboardCardId';
import DashboardCard from './DashboardCard';

export const DashboardColumnResizer = ({
  disabled,
  ...props
}: PanelResizeHandleProps) => {
  return (
    <PanelResizeHandle disabled={disabled} {...props}>
      {!disabled && (
        <Box
          sx={{
            position: 'absolute',
            width: '8px',
            height: '50%',
            background: '#f5f8fb',
            border: '1px solid #eee',
            borderRadius: '8px',
            transform: 'translate(-50%, 50%)',
            ':hover': {
              background: '#007aff4c',
              border: '1px solid #3e5fe4',
            },
          }}
        />
      )}
    </PanelResizeHandle>
  );
};

interface Props {
  columnId: UniqueIdentifier;
  items: UniqueIdentifier[];
  layoutMode?: boolean;
  active?: Active | null;
  addColumn?: (column: UniqueIdentifier, direction: 'left' | 'right') => void;
  removeColumn?: (column: UniqueIdentifier) => void;
  removeItem?: (item?: UniqueIdentifier) => void;
  addCard?: ReactNode;
}

const DashboardColumn = ({
  active,
  addCard,
  addColumn,
  columnId,
  items,
  layoutMode,
  removeColumn,
  removeItem,
}: Props) => {
  const {attributes, listeners, setNodeRef, transform, transition} = useSortable({
    id: columnId,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const isActive = active && (columnId == active.id || items.includes(active.id));

  return (
    <Box
      ref={setNodeRef}
      {...attributes}
      style={style}
      sx={{
        display: 'flex',
        gap: 2,
        flexDirection: 'column',
        flex: 1,
        padding: 1,
        ...(layoutMode
          ? {
              background: '#f5f8fb',
              border: '1px solid #e6ebf3',
              borderRadius: '8px',
              ':hover': {
                background: '#007aff4c',
                border: '1px solid #3e5fe4',
              },
              ...(isActive
                ? {
                    opacity: 0.5,
                    background: '#007aff4c',
                    border: '1px solid #3e5fe4',
                  }
                : {}),
            }
          : {}),
      }}
    >
      {layoutMode && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            component={DotsSixVertical}
            fontSize="20px"
            mr={1}
            sx={{
              color: (theme) => {
                return theme.palette.grays.five;
              },
              cursor: 'move',
            }}
            weight="bold"
            {...listeners}
          />
          <Box>
            <IconButton
              size="small"
              onClick={() => {
                return addColumn?.(columnId, 'left');
              }}
            >
              <Tooltip title="Add column to left">
                <SidebarSimple />
              </Tooltip>
            </IconButton>

            <IconButton
              size="small"
              onClick={() => {
                return addColumn?.(columnId, 'right');
              }}
            >
              <Tooltip title="Add column to right">
                <SidebarSimple style={{transform: 'rotate(180deg)'}} />
              </Tooltip>
            </IconButton>

            <IconButton
              size="small"
              onClick={() => {
                return removeColumn?.(columnId);
              }}
            >
              <Tooltip title="Remove column">
                <X />
              </Tooltip>
            </IconButton>
          </Box>
        </Box>
      )}
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {items.map((item) => {
          const isActive = active?.id == item;
          return (
            <DashboardCard
              key={item}
              cardId={item as DashboardCardId}
              draggable={layoutMode}
              hideable={layoutMode}
              sx={{
                ...(isActive
                  ? {
                      opacity: 0.5,
                    }
                  : {}),
              }}
              onHide={(item) => {
                return removeItem?.(item);
              }}
            />
          );
        })}
      </SortableContext>
      {layoutMode && addCard}
    </Box>
  );
};

export default DashboardColumn;
